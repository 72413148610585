import {AUTH_URL, FRONTEND_REDIRECT_URL} from "../config/constants";

Office.onReady(() => {
  const params = new URLSearchParams(window.location.search);
  console.log("params >>>", params)
  const error = params.get('error');
  const errorCode = params.get('errorCode');
  const accessToken = params.get('access_token');
  const expiresAt = params.get('expires_at');

  if(error || errorCode) {
    console.log("Error occurred", error);
    let data = {
      error,
      errorCode
    }
    Office.context.ui.messageParent(JSON.stringify(data));
    window.close();
  } else {
    if(accessToken) {
      const tokenDetails = {
        token: accessToken,
        expiresAt: expiresAt
      }
  
      console.log("Sending auth complete message through dialog, Payload: ", tokenDetails);
      Office.context.ui.messageParent(JSON.stringify(tokenDetails));
      window.close();
  
    } else {
      console.log("FRONTEND_REDIRECT_URL", FRONTEND_REDIRECT_URL);
      window.location = `${AUTH_URL}?redirect_url=${FRONTEND_REDIRECT_URL}`;
      // window.location = "https://4f44-2405-201-5018-8805-794f-99f2-cf47-db4d.ngrok-free.app/oauth/microsoft/signin?redirect_url=https://localhost:3000/auth.html";
    }
  }
});